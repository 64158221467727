<template>
   <v-text-field 
    filled 
    class="noline general-custom-field roboto f14 secondary-1--text fw500" 
    dense
    prepend-inner-icon="mdi-magnify"
    placeholder="Search"
    hide-details
  />
</template>

<script>
export default {

}
</script>
